<template>
    <CoreProductTray
        :id="id"
        :listId="listId"
        :listName="listName"
        :contentModuleId="contentModuleId"
        :contentTitle="contentTitle"
        :imagePreset="imagePreset"
        :products="products"
        :headerLink="headerLink"
        :headerTitle="headerTitle"
        :headerSubtitle="headerSubtitle"
        :totalProducts="totalProducts"
        :mobilePeek="mobilePeek"
        :mobilePeekPercent="mobilePeekPercent"
        :mediumPeekPercent="mediumPeekPercent"
        :displayCounter="displayCounter"
        :desktopSlideSize="desktopSlideSize"
        :xlDesktopSlideSize="xlDesktopSlideSize"
        :isFullBleedDesktop="isFullBleedDesktop"
        :mediumSlideSize="mediumSlideSize"
        :mobileSlideSize="mobileSlideSize"
        :translucentButtons="translucentButtons"
        :lightButtons="lightButtons"
        :lightInfoContainer="lightInfoContainer"
        :catalogResponseId="catalogResponseId"
        :showProductInfo="showProductInfo"
        :showEmptyContentSlot="showEmptyContentSlot"
        :showClosetButtonOnTile="showClosetButtonOnTile"
        :showReviewButtonOnTile="showReviewButtonOnTile"
        :showAddToList="showAddToList"
        :showShopTheLookBanner="showShopTheLookBanner"
        :fillEmptyPageWithBlankTiles="fillEmptyPageWithBlankTiles"
        :carouselItemUniqueKey="carouselItemUniqueKey"
        :analyticsType="analyticsType"
    >
        <template #tile="compProps">
            <CatalogTile v-bind="compProps" />
        </template>
        <template #empty-content>
            <slot name="empty-content"></slot>
        </template>
    </CoreProductTray>
</template>

<script setup>
import { onBeforeMount } from 'vue';

import { useActions } from '~coreModules/core/js/composables/vuexHelpers';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { GLOBAL_IMAGE_PRESETS } from '~coreModules/core/js/constants';
import { getCatalogTrayAnalyticsData } from '~modules/catalog/js/catalog-utils';

import ProductTrayProps from '~coreModules/core/components/mixins/productTrayProps';
import CoreProductTray from '~coreModules/core/components/ui/trays/CoreProductTray.vue';
import CatalogTile from '~modules/catalog/components/CatalogTile.vue';

// NU_TODO: https://urbnit.atlassian.net/browse/TYP-26682
// Remove below when we update to vue-eslint-parser v9.0.0 or later
/* eslint-disable vue/valid-define-props */
const props = defineProps({
    ...ProductTrayProps.props,
    products: {
        type: Array,
        required: true,
    },
    totalProducts: {
        type: Number,
        default: 15,
    },
    showEmptyContentSlot: {
        type: Boolean,
        default: false,
    },
    showClosetButtonOnTile: {
        type: Boolean,
        default: true,
    },
    showReviewButtonOnTile: {
        type: Boolean,
        default: false,
    },
    fillEmptyPageWithBlankTiles: {
        type: Boolean,
        default: false,
    },
    lightInfoContainer: {
        type: Boolean,
        default: true,
    },
    catalogResponseId: {
        type: String,
        default: null,
    },
    carouselItemUniqueKey: {
        type: String,
        default: null,
    },
    analyticsType: {
        type: String,
        default: null,
    },
    traceId: {
        type: String,
        default: null,
    },
    closetListId: {
        type: String,
        default: null,
    },
    viewAllTileLink: {
        type: Object,
        default: null,
    },
});

const imagePreset = GLOBAL_IMAGE_PRESETS.productTray;

const { trackGlobalEvent } = useActions({ trackGlobalEvent: GLOBAL_EVENT });

function trackViewProductList() {
    if (!props.listName) return;

    trackGlobalEvent(getCatalogTrayAnalyticsData({
        products: props.products,
        catalogResponseId: props.catalogResponseId,
        filters: props.filters,
        traceId: props.traceId,
        listName: props.listName,
        listId: props.listId,
    }));
}

onBeforeMount(() => {
    if (process.env.VUE_ENV === 'client') {
        trackViewProductList();
    }
});
</script>
