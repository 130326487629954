<i18n>
[
    "global__viewAll",
]
</i18n>

<template>
    <div v-if="isMounted" class="c-product-tray-module">
        <ProductTrayHeader
            :link="headerLink"
            :linkPlacement="headerLinkPlacement"
            :title="headerTitle"
            :subtitle="headerSubtitle"
            :totalProducts="totalProducts"
            :displayCounter="displayCounter"
            :numberOfItemsInView="smallestNumberOfItemsInView"
            :contentModuleId="contentModuleId"
            :contentTitle="contentTitle"
            :isFullBleedDesktop="isFullBleedDesktop"
        >
            <template #below-header-content>
                <slot name="below-header-content"></slot>
            </template>
        </ProductTrayHeader>
        <slot v-if="showEmptyContentSlot" name="empty-content"></slot>
        <template v-else>
            <Carousel
                ref="carouselRef"
                :class="{
                    'c-product-tray-module__carousel': true,
                    'is-flex': numberOfBlankTiles,
                }"
                v-bind="fullBleedDesktopProps"
                :name="id"
                :carouselItems="productsWithBlankTiles"
                :desktopSlideSize="desktopSlideSize"
                :xlDesktopSlideSize="xlDesktopSlideSize"
                :mobileSlideSize="mobileSlideSize"
                :mobilePeek="mobilePeek"
                :mobilePeekPercent="mobilePeekPercent"
                :mediumPeekPercent="mediumPeekPercent"
                :translucentButtons="translucentButtons"
                :lightButtons="lightButtons"
                :catalogResponseId="catalogResponseId"
                :resetOnResize="true"
                :carouselItemUniqueKey="carouselItemUniqueKey"
                :analyticsType="analyticsType"
                :selectedCuration="selectedCuration"
                @carousel-moved="carouselMoved"
            >
                <template
                    v-for="(_, index) in productsWithBlankTiles"
                    :key="getSlotName(index)"
                    #[getSlotName(index)]
                >
                    <LazyLoad
                        v-slot="{ hasEnteredViewport }"
                        :class="{
                            'c-product-tray-module__blank-tile': !products[index],
                        }"
                    >
                        <slot
                            name="tile"
                            :hasEnteredViewport="hasEnteredViewport"
                            :product="products[index]"
                            :imagePreset="imagePreset"
                            :hideInfo="!showProductInfo"
                            :showClosetButton="showClosetButtonOnTile"
                            :showReviewButton="showReviewButtonOnTile"
                            :smallClosetButton="true"
                            :productTrayId="id"
                            :productListId="listId"
                            :productListName="listName"
                            :useBottomMargin="false"
                            :catalogResponseId="catalogResponseId"
                            :showLikeButton="showLikeButton"
                            :showAddToList="showAddToList"
                            :showShopTheLookBanner="products[index]?.showShopTheLookBanner"
                            :lightInfoContainer="lightInfoContainer"
                            :index="index"
                            :closetListId="closetListId"
                            :selectedCuration="selectedCuration"
                            :faceOutImage="faceOutImage"
                            class="c-product-tray-module__tile"
                            @toggle-save-product-clicked="toggleSaveProductClicked"
                        ></slot>
                    </LazyLoad>
                </template>
                <template
                    v-if="products.length && viewAllTileLink"
                    #[getSlotName(products.length)]
                >
                    <ViewAllTile :link="viewAllTileLink" />
                </template>
            </Carousel>
        </template>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';

import { useMounted } from '~coreModules/core/js/composables/useMounted';
import { useMediaQueries } from '~coreModules/core/js/composables/useMediaQueries';

import { getCarouselSlotName } from '~coreModules/core/js/carousel-utils';

import { PRODUCT_TRAY_DEFAULT_PEEK_LARGE } from '~coreModules/core/js/carousel-constants';

import Carousel from '~coreModules/core/components/ui/Carousel.vue';
import ProductTrayHeader from '~coreModules/core/components/ui/trays/ProductTrayHeader.vue';
import ViewAllTile from '~coreModules/catalog/components/ViewAllTile.vue';
import LazyLoad from '~coreModules/core/components/ui/LazyLoad.vue';
import ProductTrayProps from '~coreModules/core/components/mixins/productTrayProps.js';

// NU_TODO: https://urbnit.atlassian.net/browse/TYP-26682
// Remove below when we update to vue-eslint-parser v9.0.0 or later
/* eslint-disable vue/valid-define-props */
const props = defineProps({
    ...ProductTrayProps.props,
    imagePreset: {
        type: [String, Object],
        required: true,
    },
    products: {
        type: Array,
        required: true,
    },
    carouselItemUniqueKey: {
        type: String,
        default: null,
    },
    totalProducts: {
        type: Number,
        default: 15,
    },
    showEmptyContentSlot: {
        type: Boolean,
        default: false,
    },
    showClosetButtonOnTile: {
        type: Boolean,
        default: true,
    },
    showReviewButtonOnTile: {
        type: Boolean,
        default: false,
    },
    fillEmptyPageWithBlankTiles: {
        type: Boolean,
        default: false,
    },
    lightButtons: {
        type: Boolean,
        default: false,
    },
    lightInfoContainer: {
        type: Boolean,
        default: true,
    },
    catalogResponseId: {
        type: String,
        default: null,
    },
    analyticsType: {
        type: String,
        default: null,
    },
    closetListId: {
        type: String,
        default: null,
    },
    isFullBleedDesktop: {
        type: Boolean,
        default: false,
    },
    viewAllTileLink: {
        type: Object,
        default: null,
    },
    selectedCuration: {
        type: String,
        default: '',
    },
});

const mediaQueries = useMediaQueries();
const isMounted = useMounted();

const emit = defineEmits(['toggle-save-product-clicked']);

const carouselRef = ref(null);
const numberOfItemsInView = ref(null);

const fullBleedDesktopProps = computed(() => {
    if (props.isFullBleedDesktop) {
        return {
            noContainerMargin: true,
            desktopPeekPercent: PRODUCT_TRAY_DEFAULT_PEEK_LARGE,
            onlyShowPaginationOnHover: true,
        };
    }
    return {};
});

const pageSize = computed(() => {
    if (mediaQueries.isSmallish) {
        return props.mobileSlideSize;
    }

    if (mediaQueries.isMedium) {
        return props.mediumSlideSize;
    }

    if (mediaQueries.isLargish) {
        return props.desktopSlideSize;
    }

    return props.xlDesktopSlideSize || props.desktopSlideSize;
});

const numberOfBlankTiles = computed(() => {
    if (!props.fillEmptyPageWithBlankTiles) {
        return 0;
    }

    if (!props.products.length &&
        ((props.isFullBleedDesktop && mediaQueries.isLargish) ||
        mediaQueries.isSmallish || mediaQueries.isMedium)) {
        return pageSize.value + 1;
    }

    const blankTileCount = pageSize.value - props.products.length;

    return blankTileCount > 0 ? blankTileCount : 0;
});

const productsWithBlankTiles = computed(() => {
    const totalProductsWithBlankTiles = [...props.products];

    if (numberOfBlankTiles.value) {
        for (let i = 0; i < numberOfBlankTiles.value; i++) {
            totalProductsWithBlankTiles.push({});
        }
    }

    if (props.viewAllTileLink) {
        totalProductsWithBlankTiles.push({});
    }

    return totalProductsWithBlankTiles;
});

const numberOfProductTilesInView = computed(() => {
    if (!numberOfItemsInView.value && numberOfBlankTiles.value) {
        return props.products.length;
    }
    return numberOfItemsInView.value - numberOfBlankTiles.value;
});

const smallestNumberOfItemsInView = computed(() => Math.min(props.totalProducts, numberOfProductTilesInView.value));

function carouselMoved(carouselInfo) {
    const { numberOfItemsInView: itemsInView } = carouselInfo;

    numberOfItemsInView.value = itemsInView;
}

function toggleSaveProductClicked(args) {
    emit('toggle-save-product-clicked', args);
}

function getSlotName(index) {
    return getCarouselSlotName({}, props.carouselItemUniqueKey, index);
}

defineExpose({
    resetCarousel: () => carouselRef.value?.resetCarousel(),
});
</script>

<style lang="scss">
    .c-product-tray-module {
        $this: &;
        position: relative;
        margin: 0 auto $nu-spacer-1;

        &__header-container {
            align-items: center;
            margin-bottom: $nu-spacer-2;
            padding: 0 $nu-spacer-2;

            @include breakpoint(large) {
                padding: 0 $nu-spacer-12;
                margin: 0 $nu-spacer-1 $nu-spacer-4;
            }
        }

        &__header {
            text-align: center;
            vertical-align: middle;
            padding-bottom: 0;

            &--mobile {
                padding: 0 $nu-spacer-2;

                @include breakpoint(large) {
                    display: none;
                }
            }

            &--desktop {
                display: none;

                @include breakpoint(large) {
                    display: block;
                }
            }
        }

        &__browse-link {
            padding-right: $nu-spacer-1;

            @include breakpoint(large) {
                grid-column-start: inherit;
                padding-right: 0;
            }
        }

        &__tile {
            position: relative;
            display: inline-block;
            width: 100%;
            vertical-align: top;
            text-align: center;
            background-color: $transparent;
            margin: 0;
        }

        &__counter {
            padding-left: $nu-spacer-1;
            white-space: nowrap;

            @include breakpoint(large) {
                padding-left: 0;
            }
        }

        &__carousel.is-flex .c-carousel__slide-container {
            display: flex;
        }

        &__blank-tile {
            height: 100%;
        }
    }
</style>
