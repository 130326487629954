<i18n>
[
    "global__viewAll",
]
</i18n>

<template>
    <div
        class="c-product-tray-header"
        :class="{
            'c-product-tray-header--below-header-content': hasBelowHeaderContent,
        }"
    >
        <div
            v-if="displayCounter || title || link"
            class="c-product-tray-header__header-container"
            :class="{
                'c-product-tray-header__header-container--full-bleed-desktop': isFullBleedDesktop
            }"
        >
            <div class="c-product-tray-header__headings u-text--center">
                <h3 v-if="title" class="u-spacer--1">
                    {{ title }}
                </h3>
                <p
                    v-if="subtitle"
                    class="o-text--caption u-text--gray"
                    :class="{
                        'u-spacer--2': !link,
                        'u-spacer--1': link && !isLinkRight,
                    }"
                >
                    {{ subtitle }}
                </p>
                <BaseLink
                    v-if="link && !isLinkRight"
                    :to="link.linkPath"
                    class="o-text--link o-text--caption u-spacer--0pt5"
                    @click="handleViewAllClick"
                >
                    {{ link.linkTitle }}
                </BaseLink>
            </div>
            <div v-if="displayCounter || (link && isLinkRight)" class="c-product-tray-header__bottom">
                <p v-if="displayCounter" class="c-product-tray-header__counter o-text--caption u-text--gray">
                    {{ slideCounter }}
                </p>
                <div v-if="link && isLinkRight" class="c-product-tray-header__browse-link">
                    <BaseLink
                        :to="link.linkPath"
                        class="o-text--link o-text--caption"
                        @click="handleViewAllClick"
                    >
                        {{ link.linkTitle }}
                    </BaseLink>
                </div>
            </div>
        </div>

        <slot name="below-header-content"></slot>
    </div>
</template>

<script setup>
import { isObject } from 'lodash-es';
import { computed, useSlots } from 'vue';
import { useRouter } from 'vue-router';

import { useActions } from '~coreModules/core/js/composables/vuexHelpers';

import { VIEW_ALL_CLICK } from '~coreModules/core/js/global-event-constants';
import { PRODUCT_TRAY_HEADER_LINK_PLACEMENTS } from '~coreModules/core/js/constants';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';

const router = useRouter();
const slots = useSlots();

// NU_TODO: https://urbnit.atlassian.net/browse/TYP-26682
// Remove below when we update to vue-eslint-parser v9.0.0 or later
/* eslint-disable vue/valid-define-props */
const props = defineProps({
    title: {
        type: String,
        default: null,
    },
    subtitle: {
        type: String,
        default: null,
    },
    link: {
        type: Object,
        default: null,
    },
    linkPlacement: {
        type: String,
        default: PRODUCT_TRAY_HEADER_LINK_PLACEMENTS.RIGHT,
    },
    totalProducts: {
        type: Number,
        default: 15,
    },
    displayCounter: {
        type: Boolean,
        default: true,
    },
    numberOfItemsInView: {
        type: Number,
        default: 0,
    },
    contentModuleId: {
        type: String,
        default: null,
    },
    contentTitle: {
        type: String,
        default: null,
    },
    isFullBleedDesktop: {
        type: Boolean,
        default: false,
    },
});

const slideCounter = computed(() => {
    let numberOfItems = props.numberOfItemsInView;

    if (!numberOfItems || numberOfItems < 0) {
        numberOfItems = '0';
    }

    return `${numberOfItems} / ${props.totalProducts}`;
});

const isLinkRight = computed(() => props.linkPlacement === PRODUCT_TRAY_HEADER_LINK_PLACEMENTS.RIGHT);

const hasBelowHeaderContent = computed(() => {
    const checkSlot = (item) => {
        let slot = item;
        if (!slot) return false;

        // If it's a function, call it (Vue 3)
        if (typeof slot === 'function') {
            slot = slot();
        }

        // If slot has elements, check them recursively
        return slot.some((node) => {
            if (node.children) {
                return checkSlot(node.children);
            }
            return true;
        });
    };

    return checkSlot(slots['below-header-content']);
});

const { trackGlobalEvent } = useActions({ trackGlobalEvent: GLOBAL_EVENT });

function handleViewAllClick() {
    let link = props.link.linkPath;

    if (isObject(link)) {
        link = router.resolve(link)?.path;
    }

    if (link) {
        trackGlobalEvent({
            type: VIEW_ALL_CLICK,
            data: {
                destination: link,
                contentTitle: props.contentTitle,
                contentModuleId: props.contentModuleId,
            },
        });
    }
}
</script>

<style lang="scss">
    .c-product-tray-header {
        $this: &;

        @include breakpoint(large) {
            margin-bottom: $nu-spacer-4;
        }

        &--below-header-content {
            margin-bottom: $nu-spacer-0pt5;

            #{$this}__headings {
                margin-bottom: 0;
            }
        }

        &__header-container {
            padding: 0 $nu-spacer-2;

            @include breakpoint(medium) {
                padding: 0 $nu-spacer-4;
            }

            @include breakpoint(large) {
                padding: 0 $nu-spacer-12;
                display: grid;
                grid-template-columns: auto 1fr auto;
                align-items: end;
            }

            &--full-bleed-desktop {
                @include breakpoint(large) {
                    padding: 0 $nu-spacer-8;
                }
            }
        }

        &__headings {
            margin-bottom: $nu-spacer-1pt5;

            @include breakpoint(large) {
                grid-column: 2;
                grid-row: 1;
                margin-bottom: 0;
            }
        }

        &__bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: $nu-spacer-2;

            @include breakpoint(large) {
                display: contents;
            }
        }

        &__counter {
            white-space: nowrap;
            line-height: 22px;

            @include breakpoint(large) {
                grid-column: 1;
                grid-row: 1;
            }
        }

        &__browse-link {
            @include breakpoint(large) {
                grid-column: 3;
                grid-row: 1;
                text-align: right;
                margin-left: auto;
            }
        }
    }
</style>
