<i18n>
[
    "global__viewAll",
]
</i18n>

<template>
    <div :style="style">
        <ProductTray
            :id="id"
            :listId="listId"
            :listName="listName"
            :contentModuleId="contentModuleId"
            :contentTitle="contentTitle"
            :products="products"
            :headerLink="trayHeaderLink"
            :headerTitle="headerTitle"
            :headerSubtitle="headerSubtitle"
            :totalProducts="totalProducts"
            :mobilePeek="mobilePeek"
            :mobilePeekPercent="mobilePeekPercent"
            :mediumPeekPercent="mediumPeekPercent"
            :displayCounter="displayCounter"
            :desktopSlideSize="desktopSlideSize"
            :xlDesktopSlideSize="xlDesktopSlideSize"
            :isFullBleedDesktop="true"
            :mediumSlideSize="mediumSlideSize"
            :mobileSlideSize="mobileSlideSize"
            :translucentButtons="translucentButtons"
            :lightButtons="lightButtons"
            :lightInfoContainer="lightInfoContainer"
            :catalogResponseId="catalogResponseId"
            :showProductInfo="showProductInfo"
            :filters="recommendationQuery"
            :analyticsType="analyticsType"
            :traceId="traceId"
            :fillEmptyPageWithBlankTiles="true"
        />
    </div>
</template>

<script setup>
import { isEmpty, merge } from 'lodash-es';
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { useGetters } from '~coreModules/core/js/composables/vuexHelpers';
import { useR15Svc } from '~coreModules/core/js/composables/useR15Svc';
import { useLogger } from '~coreModules/core/js/composables/useLogger';

import { SITE_SETTINGS_MODULE_NAME } from '~modules/site-settings/js/site-settings-store';

import { getCatalogItems } from '~modules/catalog/js/catalog-api';

import ProductTrayProps from '~coreModules/core/components/mixins/productTrayProps.js';
import ProductTray from '~modules/core/components/ui/ProductTray.vue';

// NU_TODO: https://urbnit.atlassian.net/browse/TYP-26682
// Remove below when we update to vue-eslint-parser v9.0.0 or later
/* eslint-disable vue/valid-define-props */
const props = defineProps({
    ...ProductTrayProps.props,
    categorySlug: {
        type: String,
        default: null,
        required: true,
    },
    categoryQuery: {
        type: Object,
        default: null,
    },
    maxProducts: {
        type: Number,
        default: 15,
    },
    shouldShowHeaderLink: {
        type: Boolean,
        default: true,
    },
    lightInfoContainer: {
        type: Boolean,
        default: true,
    },
    paddingBottom: {
        type: String,
        default: null,
    },
    analyticsType: {
        type: String,
        default: null,
    },
    traceId: {
        type: String,
        default: null,
    },
    hasEnteredViewport: {
        type: Boolean,
        default: false,
    },
});

const { t } = useI18n();
const r15Svc = useR15Svc();
const logger = useLogger();

const products = ref([]);
const totalProducts = ref(props.maxProducts);
const catalogResponseId = ref(null);

const { catalogFilterWhitelist } = useGetters(SITE_SETTINGS_MODULE_NAME, ['catalogFilterWhitelist']);

const trayHeaderLink = computed(() => {
    if (!props.shouldShowHeaderLink) {
        return null;
    }

    return props.headerLink || {
        linkPath: `/rent/browse/${props.categorySlug}`,
        linkTitle: t('global__viewAll'),
    };
});

const recommendationQuery = computed(() => {
    let query = {
        itemsPerPage: props.maxProducts,
    };

    if (!isEmpty(props.categoryQuery)) {
        query = merge(query, props.categoryQuery);
    }

    return query;
});

const style = computed(() => {
    if (props.paddingBottom) {
        return {
            paddingBottom: props.paddingBottom,
        };
    }

    return {};
});

async function getCatalogTrayProducts() {
    try {
        const { products: returnedProducts, responseId } = await getCatalogItems({
            r15Svc,
            catalogSlug: props.categorySlug,
            query: recommendationQuery.value,
            catalogFilterWhitelist: catalogFilterWhitelist.value,
            logger,
        });

        if (returnedProducts.length < props.maxProducts) {
            totalProducts.value = returnedProducts.length;
        }

        catalogResponseId.value = responseId;
        products.value = returnedProducts;
    } catch (error) {
        logger.debugError(`Failed to fetch recommendation items for ${props.categorySlug}: `, error);
        Promise.reject(error);
    }
}

watch(() => props.hasEnteredViewport, async (newValue, oldValue) => {
    if (!oldValue && newValue) {
        await getCatalogTrayProducts();
    }
}, { immediate: true });
</script>
